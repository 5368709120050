import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "./header.module.css"
import sharedStyles from "../../shared/shared.module.css"

import Tigerface from "../../svg/tigerface.svg"

const links = [
  {
    title: 'Home',
    url: '/'
  },
  {
    title: 'Products',
    url: '/products/'
  },
  {
    title: 'About',
    url: '/about/'
  },
  {
    title: 'Contact',
    url: '/contact/'
  },
  {
    title: 'Blog',
    url: '/blog/'
  }
];

const HeaderLinks = () => links.map((link, i) => (
  <Link
    key={i}
    to={link.url}
    className={styles.headerLink + ' ' + sharedStyles.tigerfaceLink}
  >
    {link.title}
  </Link>
));

class Header extends React.Component {
  state = {
    collapsed: true
  }

  handleTogglerClick() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  closeMenu() {
    this.setState({
      collapsed: true
    });
  }

  render() {
    return (
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <Tigerface className={styles.headerLogo} />
          <div className={styles.headerTitle}>
            <Link
              to="/"
              style={{
                color: `#333`,
                textDecoration: `none`,
              }}
            >
              {this.props.siteTitle.toUpperCase()}
            </Link>
          </div>
          <button
            className={styles.headerToggler + (this.state.collapsed ? ' collapsed' : '')}
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded={!this.state.collapsed}
            aria-label="Toggle navigation"
            onClick={this.handleTogglerClick.bind(this)}
          >
            <span className={styles.headerTogglerIcon}></span>
          </button>
          <div className={styles.headerLinks + ' ' + (this.state.collapsed ? styles.headerLinksCollapsed : '')} id="navbarSupportedContent">
            <HeaderLinks
              onClick={this.closeMenu.bind(this)}
            />
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
