import React from "react"

import styles from "./footer.module.css"
import Tigerface from "../../svg/tigerface.svg"

const TigerfaceFooter = () => (
  <footer className={styles.footer}>
    <div className={styles.footerContent}>
      <div className={styles.footerLeft}>
        <div className={styles.footerLogo}>
          <Tigerface className={styles.footerLogoImage}/>
          <span className={styles.footerLogoText}>TIGERFACE SYSTEMS</span>
        </div>
      </div>
      <div className={styles.footerRight}>
        <p>
          Tigerface Systems provides production-ready Salesforce solutions that offer best in-class quality and 
          robustness at affordable prices. With a pay-as-you-go and no up-front cost model, customers can utilize 
          our solutions to deliver enhanced user experiences at reduced cost.
        </p>
        <hr className={styles.divider} />
        <div className={styles.small}>
          © {new Date().getFullYear()} Tigerface Systems, LLC
        </div>
      </div>
    </div>
  </footer>
);

export default TigerfaceFooter;
